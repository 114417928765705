// src/components/About.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './About.css';

function About() {
  const { t } = useTranslation();

  return (
    <div className="about-container">
      <h2>{t('anms.menu.about')}</h2>
      <p>{t('anms.about.associate-with-us')}</p>
      <p>{t('anms.about.associate-with-us.description')}</p>
    </div>
  );
}

export default About;
