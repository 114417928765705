// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en'],
    fallbackLng: 'en',
    debug: true, // Enable debug mode to see more information in the console
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/assets/i18n/{{lng}}.json', // Ensure this path is correct
    },
  });

export default i18n;
