import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Geo.css';

function Geo() {
  const images = [
    '1.png', '3-b.png', '3.png', '7.png', '8.png',
    '9.png', '10.png', '11.png', '15.png', '19-a.png',
    '19.png', '20-a.png', '20-b.png', '20.png', '24.png',
    '27.png', '36.png', '43.png', '45.png', '52-a.png',
    '52-c.png', '52.png'
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    cssEase: 'linear'
  };

  return (
    <div className="geo-container">
      <h2>Geo solutions</h2>
      <Slider {...settings}>
        {images.map((img, index) => (
          <div key={index} className="slide-container">
            <img src={`/assets/geo/${img}`} alt={`Geo ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Geo;