// src/components/Contact.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Contact.css';

function Contact() {
  const { t } = useTranslation();

  return (
    <div className="contact-container">
      <h2>{t('anms.menu.contact')}</h2>
      <p>{t('anms.about.get-notified')}</p>
      <p>{t('anms.about.get-notified.description')}</p>
      <p>{t('anms.contact.contact.address')}</p>
    </div>
  );
}

export default Contact;
