import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Geo from './components/Geo';
import Downloads from './components/Downloads';

function App() {
  const { t } = useTranslation();

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="header-content">
            <img src="/assets/logo.png" alt="Logo" className="logo" />
            <h1>{t('anms.title')}</h1>
          </div>
          <nav>
            <ul>
              
              <li><Link to="/geo">{t('anms.menu.geosolutions')}</Link></li>

              <li><Link to="/">{t('anms.menu.about')}</Link></li>
              <li><Link to="/contact">{t('anms.menu.contact')}</Link></li>
              <li><Link to="/downloads">Downloads</Link></li>
            </ul>
          </nav>
        </header>
        <main>
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/geo" element={<Geo />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/downloads" element={<Downloads />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;