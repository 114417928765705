import React from 'react';
import './Downloads.css';

function Downloads() {
  const appDescription = "SeisTrans is advanced software for machine learning-assisted reservoir characterization in the Oil and Gas Industry. It utilizes Convolutional Neural Networks (CNN) and Transformer models for enhanced reservoir property prediction and characterization.";

  return (
    <div className="downloads-container">
      <h2>Downloads</h2>
      <div className="download-item">
        <h3>SeisTrans</h3>
        <p>{appDescription}</p>
        <a href="/assets/downloads/SeisTrans.exe" download className="download-button">
          Download SeisTrans
        </a>
      </div>
    </div>
  );
}

export default Downloads;